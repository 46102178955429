/**
 * A higher order component to inject the chapter images query into the wrapped
 * CardWithButton component.
 *
 * Until Gatsby implements a way to put variables in StaticQuery, this is just
 * a copy-pasted solution from Links/common.js for the eis-chapter folder.
 */

import React from "react";

import { StaticQuery, graphql } from "gatsby";

export default function withImageData(WrappedComponent) {
  return function WrappedWithImageData(props) {
    return (
      <StaticQuery
        query={graphql`
          query ChapterImages {
            images: allFile(
              filter: {
                extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                sourceInstanceName: { eq: "images" }
              }
            ) {
              edges {
                node {
                  id
                  name
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <WrappedComponent {...props} imageData={data} />}
      />
    );
  };
}
