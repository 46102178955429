import React from "react";

import Img from "gatsby-image";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import withImageData from "./common";

const CardWithButton = withImageData((props) => {
  const buildImage = () => {
    if (props.type === "icon") {
      return (
        <Card.Img
          as={props.icon.type}
          size={props.icon.size}
          color={props.icon.color}
          className={props.icon.className}
          variant="top"
        />
      );
    }

    const image = props.imageData.images.edges.find(({ node }) => {
      return node.name === props.fileName;
    });
    return image ? (
      <Card.Img
        as={Img}
        fluid={image.node.childImageSharp.fluid}
        style={props.imgStyle}
        variant="top"
      />
    ) : (
      <>{props.fileName}</>
    );
  };

  return (
    <Card style={props.style}>
      {buildImage()}

      <Card.Body className="d-flex flex-column">
        <Card.Title style={props.title.style}>{props.title.text}</Card.Title>

        {props.subtitle && (
          <Card.Subtitle style={props.subtitle.style}>
            {props.subtitle.text}
          </Card.Subtitle>
        )}

        {props.text && (
          <Card.Text style={props.text.style}>{props.text.text}</Card.Text>
        )}

        {props.button && (
          <div className="mt-auto">
            <Button {...props.button.buttonProps}>{props.button.text}</Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
});

CardWithButton.propTypes = {
  type: PropTypes.oneOf(["image", "icon"]).isRequired,
  style: PropTypes.object,
  fileName: PropTypes.string,
  imgStyle: PropTypes.object,
  icon: PropTypes.shape({
    type: PropTypes.elementType.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  }),
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  button: PropTypes.shape({
    text: PropTypes.string,
    buttonProps: PropTypes.object,
  }),
};

CardWithButton.defaultProps = {
  type: "image",
};

export default CardWithButton;
