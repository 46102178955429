import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import CardImageLink from "components/Links/CardImageLink";
import SectionHeading from "components/Sections/SectionHeading";

import withDocumentData from "./DocumentWrapper";

/**
 * Render the EIS chapter as a Card with a link to download the file.
 */
const ChapterCard = withDocumentData((props) => {
  const { image, imageStyle, title, subtitle, text, document } = props;
  const cardProps = {
    type: "image",
    fileName: image,
    imgStyle: imageStyle,
    title: { text: title },
    subtitle: {
      text: subtitle,
      style: {
        marginBottom: "0.5rem",
      },
    },
    text: {
      text,
    },
    url: {
      path: props.getDocumentURLByName(props.documents, document),
      isExternal: true,
    },
  };

  return (
    <Col
      xs={12}
      sm={6}
      md={3}
      className="d-flex align-items-stretch mb-3 pr-sm-2"
    >
      <CardImageLink {...cardProps} />
    </Col>
  );
});

/**
 * Render the EIS Chapter list in a table with links to download the files.
 */
const ChapterTable = withDocumentData((props) => {
  return (
    <Col xs={12}>
      <Table striped bordered responsive>
        <thead>
          <tr>
            {props.tableProps.headers.map(({ width, text }, index) => {
              return (
                <th key={index} width={width}>
                  {text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.chapters.map(({ chapter, tableFiles }, index) => {
            return (
              <tr key={index}>
                <td>{chapter}</td>
                <td>
                  {tableFiles.map(
                    ({ title, document, docInDocsFolder }, fileIndex) => {
                      const documentUrl = docInDocsFolder
                        ? props.getDocumentURLByName(props.documents, document)
                        : `/pdf-documents/${document}.pdf`;

                      return (
                        <div key={fileIndex}>
                          <a
                            href={documentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {title}
                          </a>
                          {tableFiles.length > 1 &&
                            fileIndex !== tableFiles.length - 1 && (
                              <span>, </span>
                            )}
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
});

const consolidatedChapterLink = withDocumentData((props) => {
  const documentUrl = props.docInDocsFolder
    ? props.getDocumentURLByName(props.documents, props.document)
    : `/pdf-documents/${props.document}.pdf`;
  return (
    <Col xs={12} className="pb-3">
      <Row className="font-weight-bold">
        <a href={documentUrl} target="_blank" rel="noopener noreferrer">
          <u>{props.title}</u>
        </a>
      </Row>
    </Col>
  );
});

const EISChaptersGroup = (props) => {
  let content;
  if (props.showAsTable) {
    content = (
      <ChapterTable chapters={props.chapters} tableProps={props.tableProps} />
    );
  } else {
    content = props.chapters.map((chapterData, chapterIndex) => {
      return <ChapterCard key={chapterIndex} {...chapterData} />;
    });
  }

  const groupHeading = (
    <Col>
      <SectionHeading
        id={props.groupHeadingId}
        heading={{
          priority: 3,
          text: props.groupHeadingName,
          style: {
            scrollMarginTop: "140px",
          },
        }}
        separator={{ style: { width: "100%" } }}
      />
    </Col>
  );

  return (
    <>
      <Row>{groupHeading}</Row>
      {props.consolidatedChapters
        ? consolidatedChapterLink(props.consolidatedChapters)
        : null}
      <Row>{content}</Row>
    </>
  );
};

EISChaptersGroup.propTypes = {
  groupHeadingName: PropTypes.string.isRequired,
  groupHeadingId: PropTypes.string.isRequired,
  consolidatedChapters: PropTypes.shape({
    title: PropTypes.string,
    document: PropTypes.string,
    docInDocsFolder: PropTypes.bool,
  }),
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      chapter: PropTypes.string,
      image: PropTypes.string,
      imageStyle: PropTypes.object,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.string,
      document: PropTypes.string,
      tableFiles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          document: PropTypes.string.isRequired,
          docInDocsFolder: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  showAsTable: PropTypes.bool,
  tableProps: PropTypes.shape({
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.string,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default EISChaptersGroup;
