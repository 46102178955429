import React from "react";

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import { formatEventDateTime } from "components/EventCard/utils";

const DATE_DISPLAY_SUMMARY_FORMAT = "d/MM/yy"; // 07/03/23
const TIME_DISPLAY_SUMMARY_FORMAT = "hh:mm a"; // 12:00 AM

const EventCardSummaryRow = (props) => {
  const upcomingEvent = props.upcomingEvent;
  return (
    <div className="mb-1">
      <Card.Text className="upcoming-event-title">
        {upcomingEvent.title}
      </Card.Text>
      <Card.Text>
        {formatEventDateTime(
          [upcomingEvent.start_date, upcomingEvent.end_date],
          DATE_DISPLAY_SUMMARY_FORMAT,
          " - "
        )}
      </Card.Text>
      <Card.Text>
        {formatEventDateTime(
          [upcomingEvent.start_time, upcomingEvent.end_time],
          TIME_DISPLAY_SUMMARY_FORMAT,
          " - "
        )}
      </Card.Text>
    </div>
  );
};

EventCardSummaryRow.propTypes = {
  upcomingEvent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.object,
  }).isRequired,
};

export default EventCardSummaryRow;
