import React from "react";

import { ButtonGroup, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

import { COMPONENT } from "components/DynamicPages/types";
import {
  deleteRow,
  setActiveComponentType,
  setActiveModal,
  setActiveRowId,
} from "state/actions";

const EditDropdown = ({ rowId }) => {
  const dispatch = useDispatch();
  const pageName = useSelector((state) => state.dynamicPages.activePageName);

  const handleEdit = () => {
    dispatch(setActiveRowId(rowId));
    dispatch(setActiveModal("dynamic-row-form"));
  };

  const handleDelete = () => {
    dispatch(deleteRow(pageName, rowId));
  };

  return (
    <DropdownButton
      title="Actions"
      as={ButtonGroup}
      className="dynamic-row-action-button"
      variant="light"
    >
      <Dropdown.Item onClick={handleEdit}>Edit Row</Dropdown.Item>
      <Dropdown.Item onClick={handleDelete}>Delete Row</Dropdown.Item>
    </DropdownButton>
  );
};

const EditableRow = (props) => {
  const dispatch = useDispatch();
  const pageName = useSelector((state) => state.dynamicPages.activePageName);
  const pages = useSelector((state) => state.dynamicPages.pages);
  const pageConfig = pages[pageName] || { config: [] };

  const existingRowConfig =
    pageConfig.config.find((item) => item.name === props.id) || {};

  const handleDrop = (item) => {
    dispatch(setActiveComponentType(item.id));
    dispatch(setActiveRowId(props.id));
    dispatch(setActiveModal("dynamic-component-form"));
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: COMPONENT,
    drop: handleDrop,
    canDrop: () => {
      // Limit the number of components to 4 per row.
      return (
        existingRowConfig.children && existingRowConfig.children.length < 4
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Set the shadow of the row when the droppable item is over the row and can
  // be dropped.
  let boxshadow = "";
  if (isOver && canDrop) {
    // Stylelint removes the boxShadow styles when the variable name is not lowercase.
    boxshadow = "inset 0px 0px 10px rgba(0, 0, 0, 0.9)";
  }

  return (
    <Row
      ref={drop}
      {...props}
      style={{ ...props.style, position: "relative", boxShadow: boxshadow }}
    >
      {props.children}
      <EditDropdown rowId={props.id} />
    </Row>
  );
};

export default EditableRow;
