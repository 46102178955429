// TODO this requires a back end to be connected for sending emails.
import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { setActiveModal } from "state/actions";

const ContactUsForm = (props) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const buttonText = props.buttonText || "Send email";

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      // TODO submit the form.
      form.reset();
      dispatch(setActiveModal("contact-us-thank-you"));
      setValidated(false);
    }
  };

  return (
    <Form
      className={props.formClassName}
      validated={validated}
      onSubmit={handleSubmit}
      noValidate
    >
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" required />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email address"
          required
        />
      </Form.Group>

      <Form.Group controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={5} required />
      </Form.Group>

      <Button type="submit" {...props.buttonProps}>
        {buttonText}
      </Button>
    </Form>
  );
};

ContactUsForm.propTypes = {
  address: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
  buttonText: PropTypes.string,
  formClassName: PropTypes.string,
};

export default ContactUsForm;
