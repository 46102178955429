import React from "react";

import PropTypes from "prop-types";

import TopicLayerDropDown from "./TopicLayerDropDown";
import TopicLayerSelector from "./TopicLayerSelector";
import { topicLayerControl } from "./types";

const TopicLayerSelectGroup = ({ layerControls, elementId }) => {
  const isLayerControls = layerControls.length > 0;

  const controls = layerControls.map((layerControl, index) => {
    const { type, title, layer, layers } = layerControl;
    const id = `${elementId}-${index}`;
    if (type === "group") {
      return (
        <TopicLayerDropDown key={id} id={id} title={title} layers={layers} />
      );
    }
    return <TopicLayerSelector key={id} {...layer} />;
  });

  if (!isLayerControls) {
    return null;
  }

  return (
    <div className="mb-2">
      <h6>Map Layers</h6>
      {controls}
    </div>
  );
};

TopicLayerSelectGroup.propTypes = {
  layerControls: PropTypes.arrayOf(topicLayerControl).isRequired,
  elementId: PropTypes.string.isRequired,
};

export default TopicLayerSelectGroup;
