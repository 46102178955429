import React from "react";

import PropTypes from "prop-types";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";

const EISExitContentLink = ({ setShowContent }) => {
  const handleClick = () => {
    setShowContent(false);
  };

  return (
    <Nav.Link onClick={handleClick} className="text-primary">
      <ArrowLeftCircleFill size={25} />
    </Nav.Link>
  );
};

EISExitContentLink.propTypes = {
  setShowContent: PropTypes.func.isRequired,
};

export default EISExitContentLink;
