import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PropTypes from "prop-types";

const BaseMarkdownRenderer = (props) => {
  const mdxItems = useStaticQuery(graphql`
    {
      submissions: allFile(
        filter: {
          extension: { eq: "mdx" }
          sourceInstanceName: { eq: "templates" }
        }
      ) {
        edges {
          node {
            name
            childMdx {
              body
            }
          }
        }
      }
    }
  `);

  const foundItem = mdxItems.submissions.edges.filter(
    (mdxItem) => mdxItem.node.name === props.name
  );
  let mdxBody = null;
  if (foundItem.length > 0) {
    mdxBody = foundItem[0].node.childMdx.body;
  }
  return <MDXRenderer>{mdxBody}</MDXRenderer>;
};

BaseMarkdownRenderer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default BaseMarkdownRenderer;
