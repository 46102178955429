import React from "react";

import Container from "react-bootstrap/Container";

const AboriginalArtworkBanner = (props) => {
  const { encapsulate } = props;
  const style = {
    objectFit: "cover",
    maxHeight: "80px",
    minHeight: "50px",
  };

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <ConditionalWrapper
      condition={encapsulate}
      wrapper={(children) => <Container>{children}</Container>}
    >
      <img
        src="/banners/Aboriginal_artwork.jpg"
        alt="Aboriginal artwork"
        className="mt-3 w-100"
        style={style}
      />
    </ConditionalWrapper>
  );
};

export default AboriginalArtworkBanner;
