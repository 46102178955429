import React, { useRef, useEffect } from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

import useToggle from "hooks/useToggle";

const EISTopicContentHidden = ({ hideContent, topicContent, mapConfig }) => {
  const [hidden, toggleHidden] = useToggle(hideContent);

  const maskRef = useRef();

  useEffect(() => {
    if (hidden !== hideContent) {
      toggleHidden();
    }
  }, [hideContent]);

  const handleClick = () => {
    maskRef.current.classList.toggle("map-topic-text-visible");
    toggleHidden();
  };

  if (!hideContent) {
    return (
      <>
        {topicContent && (
          <MDXRenderer name={topicContent.node.name} {...mapConfig}>
            {topicContent.node.childMdx.body}
          </MDXRenderer>
        )}
      </>
    );
  }

  return (
    <>
      <div
        ref={maskRef}
        className="position-relative overflow-hidden map-topic-text"
      >
        <div className="position-absolute w-100 h-100 map-topic-mask" />
        {topicContent && (
          <MDXRenderer name={topicContent.node.name} {...mapConfig}>
            {topicContent.node.childMdx.body}
          </MDXRenderer>
        )}
      </div>
      <Nav.Link
        onClick={handleClick}
        className="pl-0 pt-0 read-more small font-weight-bold"
      >
        {hidden ? "...Read More" : "Read less"}
      </Nav.Link>
    </>
  );
};

EISTopicContentHidden.propTypes = {
  hideContent: PropTypes.bool.isRequired,
  topicContent: PropTypes.object.isRequired,
  mapConfig: PropTypes.object,
};

export default EISTopicContentHidden;
