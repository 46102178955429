export const hotspotConfig = [
  {
    elementId: "page-usage-info",
    tooltipText:
      "Click and drag the mouse or use the arrow keys to navigate around the room. Click the icons to view media or visit links.",
    contentType: "hotspot",
    position: {
      yaw: 1.5156860841923532,
      pitch: -0.45385566966302278,
    },
  },
  {
    elementId: "facilitated-impacts-brochure",
    itemLabel: "Facilitated Impacts Brochure",
    tooltipText: "Facilitated Impacts Brochure",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-facilitated-impacts-brochure-oct-23.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -0.6372644656243374,
      pitch: 0.006711948707069482,
    },
  },
  {
    elementId: "noise-insulation-and-property-acquisition-brochure",
    itemLabel: "Noise Insulation and Property Acquisition Brochure",
    tooltipText: "Noise Insulation and Property Acquisition Brochure",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-noise-insulation-policy-oct-23.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -0.47025712089810057,
      pitch: 0.0004520839087476247,
    },
  },
  {
    elementId: "noise-tool",
    itemLabel: "Aircraft Overflight Noise Tool",
    tooltipText: "Aircraft Overflight Noise Tool",
    contentType: "link",
    linkProps: {
      path: "https://wsiflightpaths.aerlabs.com",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 0.010049772167697313,
      pitch: -0.052823499965963805,
    },
  },
  {
    elementId: "digital-eis",
    itemLabel: "Digital EIS",
    tooltipText: "Digital EIS",
    contentType: "link",
    linkProps: {
      path: "/digital-draft-eis",
    },
    position: {
      yaw: 0.008516218913165119,
      pitch: 0.1805053328802586,
    },
  },
  {
    elementId: "poster-02-noise-assessment-brochure",
    itemLabel: "Noise Assessment Brochure",
    tooltipText: "Noise Assessment Brochure",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-noise-assessment-brochure-oct-23.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 0.4677922482223327,
      pitch: 0.00186108308190569,
    },
  },
  {
    elementId: "poster-03-preliminary-flight-path-brochure",
    itemLabel: "Preliminary Flight Paths Brochure",
    tooltipText: "Preliminary Flight Paths Brochure",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-flight-path-brochure-oct-23.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 0.643683553781214,
      pitch: 0.0016693831988217056,
    },
  },
  {
    elementId: "environmental-impact-assessment-heritage",
    itemLabel: "Environmental Impact Assessment: Heritage",
    tooltipText: "Environmental Impact Assessment: Heritage",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-5-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 1.061768260241319,
      pitch: 0.01614754177758293,
    },
  },
  {
    elementId: "environmental-impact-assessment-aircraft",
    itemLabel: "Environmental Impact Assessment: Aircraft Hazard & Air quality",
    tooltipText:
      "Environmental Impact Assessment: Aircraft Hazard & Air quality",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-1-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 1.3849320465154662,
      pitch: 0.018244924923452643,
    },
  },
  {
    elementId: "environmental-impact-assessment-land-use",
    itemLabel: "Environmental Impact Assessment: Land Use",
    tooltipText: "Environmental Impact Assessment: Land Use",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-2-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 1.6876276299895192,
      pitch: 0.020049260180606154,
    },
  },
  {
    elementId: "environmental-impact-assessment-landscape",
    itemLabel: "Environmental Impact Assessment: Landscape and Visual Amenity",
    tooltipText:
      "Environmental Impact Assessment: Landscape and Visual Amenity",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-3-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 1.9896548093567592,
      pitch: 0.015643067529774513,
    },
  },
  {
    elementId: "environmental-impact-assessment-biodiversity",
    itemLabel: "Environmental Impact Assessment: Biodiversity",
    tooltipText: "Environmental Impact Assessment: Biodiversity",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-4-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 2.2644253886255683,
      pitch: 0.015640702340245838,
    },
  },
  {
    elementId: "environmental-impact-assessment-Social",
    itemLabel: "Environmental Impact Assessment: Social",
    tooltipText: "Environmental Impact Assessment: Social",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-6-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -2.2764432694936065,
      pitch: 0.016682598056796394,
    },
  },
  {
    elementId: "environmental-impact-assessment-economic",
    itemLabel: "Environmental Impact Assessment: Economic",
    tooltipText: "Environmental Impact Assessment: Economic",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-7-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -1.9803515357515273,
      pitch: 0.025486625162933763,
    },
  },
  {
    elementId: "environmental-impact-assessment-human-health",
    itemLabel: "Environmental Impact Assessment: Human Health",
    tooltipText: "Environmental Impact Assessment: Human Health",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-8-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -1.6682162581921602,
      pitch: 0.025740885225760124,
    },
  },
  {
    elementId: "environmental-impact-assessment-greater-blue",
    itemLabel: "Environmental Impact Assessment: Greater Blue Mountain Area",
    tooltipText: "Environmental Impact Assessment: Greater Blue Mountain Area",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-EIS-topics-9-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -1.3364658552817872,
      pitch: 0.027759354993253993,
    },
  },
  {
    elementId: "fact-sheet-04-eis-process",
    itemLabel: "EIS Process",
    tooltipText: "EIS Process",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-Fact-sheet-EIS-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -1.0838023575382714,
      pitch: 0.03022386625046991,
    },
  },
  {
    elementId: "airspace-flight-path",
    itemLabel: "Airport Airspace Design And Flight Path Journey",
    tooltipText: "Airport Airspace Design And Flight Path Journey",
    contentType: "link",
    linkProps: {
      path: "https://www.youtube.com/embed/ZdvMQD9S7yY",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 2.8511859060234306,
      pitch: 0.15727289470411598,
    },
  },
  {
    elementId: "the-eis-process",
    itemLabel: "The EIS Process",
    tooltipText: "The EIS Process",
    contentType: "link",
    linkProps: {
      path: "https://www.youtube.com/embed/5X4Om7cpPb0",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -3.1350790266955517,
      pitch: 0.16915765836572128,
    },
  },
  {
    elementId: "sydney-airspace",
    itemLabel: "The Sydney Airspace",
    tooltipText: "The Sydney Airspace",
    contentType: "link",
    linkProps: {
      path: "https://www.youtube.com/embed/NUOwWz5e1Qs",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -2.84115259010089,
      pitch: 0.1621250340928384,
    },
  },
  {
    elementId: "contact-us",
    itemLabel: "Contact us",
    tooltipText: "Contact us",
    contentType: "link",
    linkProps: {
      path: "/contact-us",
    },
    position: {
      yaw: -2.561626252435568,
      pitch: 0.13738833054969213,
    },
  },
  {
    elementId: "project-wsi-nancy-bird-walton-postcard",
    itemLabel: "Nancy-Bird Walton Postcard",
    tooltipText: "Nancy-Bird Walton Postcard",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-Nancy-Bird-Walton-postcard.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -3.120964458728304,
      pitch: 0.2644023326013709,
    },
  },
  {
    elementId: "fact-sheet-07-contacts",
    itemLabel: "Who do I talk to?",
    tooltipText: "Who do I talk to?",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-Fact-sheet-Who-do-I-talk-with.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: 2.9031452243301787,
      pitch: 0.2517189833741327,
    },
  },
  {
    elementId: "fact-sheet-08-faq",
    itemLabel: "Frequently Asked Questions",
    tooltipText: "Frequently Asked Questions",
    contentType: "link",
    linkProps: {
      path: "/pdf-documents/WSI-Fact-sheet-FAQs-oct-2023.pdf",
      target: "_blank",
      isExternal: true,
    },
    position: {
      yaw: -2.8655868404498417,
      pitch: 0.24838786441037364,
    },
  },
];
