/**
 * An empty spacer to use when laying out columns/components in a Row and
 * extra space is required between components.
 */
import React from "react";

const Spacer = () => {
  return <div className="w-100" />;
};

export default Spacer;
