import React from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SectionHeading from "./SectionHeading";

const SectionHeadingWithButtons = (props) => {
  const { disableSkip, sectionHeadingProps, buttons } = props;

  const sectionHeading = (
    <Col>
      <SectionHeading {...sectionHeadingProps} />
    </Col>
  );

  const renderSkipToSection = () => {
    if (disableSkip) {
      return null; // If disableSkip is true, skip rendering the "Skip to section" code
    }

    return (
      <Row>
        <Col>
          <p className="mb-0 mt-2 font-weight-bold">Skip to section</p>
        </Col>
      </Row>
    );
  };

  const renderButtons = () =>
    buttons &&
    buttons.map((buttonProps, buttonIndex) => (
      <Col key={buttonIndex} xs="auto">
        <Button className="mr-2 mt-2" {...buttonProps.props}>
          {buttonProps.text}
        </Button>
      </Col>
    ));

  return (
    <>
      <Row>{sectionHeading}</Row>
      {renderSkipToSection()}
      <Row className="justify-content-start" noGutters={true}>
        {renderButtons()}
      </Row>
    </>
  );
};

SectionHeadingWithButtons.propTypes = {
  disableSkip: PropTypes.bool, // New prop to enable/disable the "Skip to section" code
  sectionHeadingProps: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      props: PropTypes.object.isRequired,
    })
  ),
};

export default SectionHeadingWithButtons;
