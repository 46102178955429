import React, { useEffect } from "react";

import { Link as GatsbyLink } from "gatsby";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import withModalTrigger from "components/Modals/ModalTrigger";
import { setActivePageName, setPagesConfig } from "state/actions";

import { STORAGE_KEY_PAGES } from "./types";

const DeletePageButton = withModalTrigger((props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setActivePageName(props.pageName));
    props.setActiveModal();
  };

  return (
    <span
      style={{ cursor: "pointer" }}
      onClick={handleClick}
      onKeyDown={(event) => (event.key === "Enter" ? handleClick() : undefined)}
      role="button"
      tabIndex={0}
    >
      Delete
    </span>
  );
});

const DownloadPageButton = ({ pageName, config }) => {
  const configFile = new Blob([JSON.stringify(config)], {
    type: "application/json",
  });

  return (
    <a href={URL.createObjectURL(configFile)} download={`${pageName}.json`}>
      Download
    </a>
  );
};

const DynamicPageList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const savedPages = JSON.parse(localStorage.getItem(STORAGE_KEY_PAGES));
    dispatch(setPagesConfig(savedPages));
  }, []);

  const pages = useSelector((state) => state.dynamicPages.pages);

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th width="50%">Page title</th>
          <th>URL slug</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        {Object.keys(pages).map((pageTitle) => {
          const pageKey = `${pageTitle}-list-item`;
          const pagePath = `/create/${pageTitle}`;

          return (
            <tr key={pageKey}>
              <td>{pages[pageTitle].pageName}</td>
              <td>{pageTitle}</td>
              <td>
                <GatsbyLink to={pagePath}>Edit</GatsbyLink> |{" "}
                <DeletePageButton
                  targetModalId="dynamic-page-delete-form"
                  pageName={pageTitle}
                />{" "}
                |{" "}
                <DownloadPageButton
                  pageName={pageTitle}
                  config={pages[pageTitle].config}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DynamicPageList;
