import { mapConfig } from "src/app-config";

import MapboxActions from "./Mapbox/MapboxActions";

const mapActionProviders = {
  Mapbox: MapboxActions,
};

const withMapActions = (Component) => {
  const { moduleComponentName } = mapConfig.mapSettings;
  const MapActions = mapActionProviders[moduleComponentName];
  return MapActions(Component);
};

export default withMapActions;
