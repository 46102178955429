import React from "react";

import PropTypes from "prop-types";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";

import { mapConfig } from "src/app-config";

import { initialMapState } from "./types";
import withMapActions from "./withMapActions";

const ExitContentLink = (props) => {
  const { setMapTopic, initialMapState, toggleVisible, clearTopic } = props;

  const handleClick = () => {
    clearTopic();
    if (mapConfig.isPrimaryMap) {
      const layerOptions = [];
      toggleVisible();
      if (!initialMapState.resetInitialLocation) {
        initialMapState.locationOptions = {};
      }
      setMapTopic(initialMapState.locationOptions, layerOptions);
    }
  };

  return (
    <Nav.Link onClick={handleClick} className="text-light">
      <ArrowLeftCircleFill size={25} />
    </Nav.Link>
  );
};

ExitContentLink.propTypes = {
  setMapTopic: PropTypes.func.isRequired,
  initialMapState: initialMapState,
  toggleVisible: PropTypes.func.isRequired,
  clearTopic: PropTypes.func.isRequired,
};

export default withMapActions(ExitContentLink);
