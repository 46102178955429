import React from "react";

import Img from "gatsby-image";
import PropTypes from "prop-types";
import * as BootstrapIcons from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";

import withLightboxTrigger from "components/Images/LightboxTrigger";
import Link from "components/Links/Link";

import { requiredLinkPropsCheck, withImageData } from "./common";

const CardImageLink = withImageData((props) => {
  const buildImage = () => {
    if (props.type === "icon") {
      let Icon = props.icon.type;
      if (typeof Icon === "string") {
        Icon = BootstrapIcons[Icon];
      }

      return (
        <Card.Img
          as={Icon}
          size={props.icon.size}
          color={props.icon.color}
          className={props.icon.className}
          variant="top"
        />
      );
    }

    const image = props.imageData.images.edges.find(({ node }) => {
      return node.name === props.fileName;
    });
    return image ? (
      <Card.Img
        as={Img}
        fluid={image.node.childImageSharp.fluid}
        style={props.imgStyle}
        variant="top"
      />
    ) : (
      <>{props.fileName}</>
    );
  };

  const cardContent = (
    <Card>
      {buildImage()}

      <Card.Body>
        <Card.Title style={props.title.style}>{props.title.text}</Card.Title>

        {props.subtitle && (
          <Card.Subtitle style={props.subtitle.style}>
            {props.subtitle.text}
          </Card.Subtitle>
        )}

        {props.text && (
          <Card.Text style={props.text.style}>{props.text.text}</Card.Text>
        )}
      </Card.Body>
    </Card>
  );

  const LightboxTrigger = withLightboxTrigger((props) => {
    return (
      <div
        onClick={props.triggerLightbox}
        onKeyDown={(event) =>
          event.key === "Enter" ? props.triggerLightbox() : undefined
        }
        tabIndex={0}
        role="button"
        className="d-flex card-image-link"
      >
        {props.children}
      </div>
    );
  });

  return props.isLightboxTrigger ? (
    <LightboxTrigger {...props.lightboxProps}>{cardContent}</LightboxTrigger>
  ) : (
    <Link {...props.url} className="card-image-link">
      {cardContent}
    </Link>
  );
});

CardImageLink.propTypes = {
  type: PropTypes.oneOf(["image", "icon"]).isRequired,
  fileName: PropTypes.string,
  imgStyle: PropTypes.object,
  icon: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
      .isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  }),
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  url: PropTypes.shape({
    path: requiredLinkPropsCheck,
    targetModalId: requiredLinkPropsCheck,
    isExternal: PropTypes.bool,
  }),
  isLightboxTrigger: PropTypes.bool,
  lightboxProps: PropTypes.shape({
    lightboxSources: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        fileName: PropTypes.string,
        title: PropTypes.string,
        src: PropTypes.string,
      })
    ),
    startingLightboxIndex: PropTypes.number,
  }),
};

CardImageLink.defaultProps = {
  type: "image",
};

export default CardImageLink;
