import React from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

import { mapConfig } from "src/app-config";

import { topics } from "./types";
import withMapActions from "./withMapActions";

const SetTopic = (props) => {
  const { setTopicId, newTopic, icon, setMapTopic } = props;

  const handleClick = () => {
    const { id, locationOptions, layerOptions } = newTopic;
    setTopicId(id);
    if (mapConfig.isPrimaryMap) {
      setMapTopic(locationOptions, layerOptions);
    }
  };

  if (_isEmpty(newTopic)) {
    return null;
  }

  return (
    <Button
      onClick={handleClick}
      variant="outline-primary"
      className="text-left"
      block
    >
      <span className="toggle-button-icon">{icon}</span>{" "}
      <span className="small">{newTopic.title}</span>
    </Button>
  );
};

SetTopic.propTypes = {
  setTopicId: PropTypes.func.isRequired,
  setMapTopic: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  newTopic: PropTypes.object,
};

const TopicNextBack = (props) => {
  const { topics, currentId, setTopicId } = props;
  const currentIndex = topics.findIndex(({ id }) => id === currentId);

  const previousTopic = currentIndex === 0 ? {} : topics[currentIndex - 1];
  const nextTopic =
    currentIndex === topics.length - 1 ? {} : topics[currentIndex + 1];

  const SetTopicWithMapActions = withMapActions(SetTopic);

  return (
    <div className="py-3">
      <SetTopicWithMapActions
        icon={<ArrowLeftCircleFill size={18} />}
        setTopicId={setTopicId}
        newTopic={previousTopic}
      />
      <SetTopicWithMapActions
        icon={<ArrowRightCircleFill size={18} />}
        setTopicId={setTopicId}
        newTopic={nextTopic}
      />
    </div>
  );
};

TopicNextBack.propTypes = {
  topics: topics,
  currentId: PropTypes.string.isRequired,
  setTopicId: PropTypes.func.isRequired,
};

export default TopicNextBack;
