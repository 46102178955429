/**
 * Render content from mdx. This is intended for use when configurable
 * components do not meet requirement and it's easier to add content
 * in mdx.
 *
 * This component will render topics from `src/templates/content`.
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PropTypes from "prop-types";

const SectionContent = ({ elementId }) => {
  const data = useStaticQuery(graphql`
    query SectionContentTemplateData {
      content: allFile(
        filter: {
          extension: { eq: "mdx" }
          sourceInstanceName: { eq: "templates" }
          relativeDirectory: { eq: "content" }
        }
      ) {
        edges {
          node {
            name
            childMdx {
              frontmatter {
                id
              }
              body
            }
          }
        }
      }
    }
  `);

  if (!elementId) {
    return null;
  }

  const pageContent = data.content.edges.filter(
    ({ node }) => node.childMdx.frontmatter.id === elementId
  )[0];

  return (
    <MDXRenderer name={pageContent.node.name}>
      {pageContent.node.childMdx.body}
    </MDXRenderer>
  );
};

SectionContent.propTypes = {
  elementId: PropTypes.string.isRequired,
};

export default SectionContent;
