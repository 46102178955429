import React, { useState } from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import TopicLayerSelectGroup from "components/InteractiveMap/TopicLayerSelectGroup";
import useToggle from "hooks/useToggle";

import ExitContentLink from "./ExitContentLink";
import TopicContent from "./TopicContent";
import TopicNextBack from "./TopicNextBack";
import TopicTitle from "./TopicTitle";
import { topics, initialMapState } from "./types";

const initialContent = {
  title: "",
  content: {
    elementId: "",
    hideContent: false,
    layerControls: [],
  },
};

const Content = (props) => {
  const { title, topics, initialMapState } = props;
  const [visible, toggleVisible] = useToggle();
  const [topicId, setTopicId] = useState("");

  const allContent = topics.reduce((accumulater, value) => {
    accumulater[value.id] = value;
    return accumulater;
  }, {});
  const topicContent = allContent[topicId] || initialContent;

  const topicSelectors = topics.map((topic) => (
    <TopicTitle
      key={topic.id}
      showContent={toggleVisible}
      setTopicId={setTopicId}
      id={topic.id}
      title={topic.title}
      locationOptions={topic.locationOptions}
      layerOptions={topic.layerOptions}
    />
  ));

  return (
    <div className="overflow-hidden">
      <Collapse in={visible} dimension="width" id="collapse-container">
        <div className="d-inline-block">
          <Container fluid className="p-0 m-0 topic-content-container">
            <Row
              noGutters
              className="bg-primary text-light topic-content-header"
            >
              <Col xs={6} className="d-flex align-items-center p-3">
                <h5 className="m-0">{title}</h5>
              </Col>
              <Col xs={6} className="d-flex align-items-center p-1">
                <ExitContentLink
                  toggleVisible={toggleVisible}
                  initialMapState={initialMapState}
                  clearTopic={() => setTopicId("")}
                />
                <h5 className="m-0">{topicContent.title}</h5>
              </Col>
            </Row>
            <Row className="topic-content-area" noGutters>
              <Col
                xs={6}
                className="d-flex flex-column justify-content-start p-2"
              >
                {topicSelectors}
              </Col>
              <Col xs={6} className="d-flex flex-column p-3">
                <TopicLayerSelectGroup
                  elementId={topicContent.content.elementId}
                  layerControls={topicContent.content.layerControls}
                />
                <TopicContent
                  elementId={topicContent.content.elementId}
                  hideContent={topicContent.content.hideContent}
                />
                <TopicNextBack
                  topics={topics}
                  currentId={topicId}
                  setTopicId={setTopicId}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>
    </div>
  );
};

Content.propTypes = {
  title: PropTypes.string.isRequired,
  topics: topics,
  initialMapState: initialMapState,
};

export default Content;
