import React, { useState } from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";

import Thumbnail from "components/Images/Thumbnail";
import PageLoadingOverlay from "components/Page/PageLoadingOverlay";

import BasemapSelector from "./BasemapSelector";
import Content from "./Content";
import { MapContextProvider } from "./MapContext";
import MapControl from "./MapControl";
import MapLegend from "./MapLegend";
import Mapbox from "./Mapbox/Mapbox";
import withMapActions from "./withMapActions";

const mapModules = {
  Mapbox,
};

const popupModules = {
  Thumbnail,
};

const MapPage = (props) => {
  const {
    mapConfig,
    layers,
    sites,
    geocoderConfig,
    layerControls,
    content,
    popups,
    icons,
    zoomToLocation,
    setLayersVisibility,
    switchBasemap,
  } = props;
  const InteractiveMap = mapModules[mapConfig.moduleComponentName];
  const layerVisibility = useSelector((state) => state.interactiveMap.layers);

  const popupsWithModule = popups.map((popup) => {
    const featureProps = popup.featureProps.map((feature) => {
      const popupModule = popupModules[feature.moduleComponentName];
      return { ...feature, module: popupModule };
    });
    return { ...popup, featureProps };
  });

  const [loading, setLoading] = useState(false);

  return (
    <MapContextProvider>
      <PageLoadingOverlay loading={loading} />
      <Row noGutters className="flex-wrap-reverse">
        <Col sm={12} md={4} xl={3}>
          <Content {...content} initialMapState={mapConfig.initialMapState} />
        </Col>
        <Col sm={12} md={8} xl={9}>
          <MapControl
            layerControls={layerControls}
            sites={sites}
            geocoderConfig={geocoderConfig}
            zoomToLocation={zoomToLocation}
            layerVisibility={layerVisibility}
            setLayersVisibility={setLayersVisibility}
          />
          <InteractiveMap
            setLoading={setLoading}
            {...mapConfig}
            layers={layers}
            popups={popupsWithModule}
            icons={icons}
          />
          <BasemapSelector
            switchBasemap={switchBasemap}
            basemaps={mapConfig.basemaps}
          />
          <MapLegend
            layers={layerVisibility}
            commonLegend={props.commonLegend}
            layerLegends={props.layerLegends}
          />
        </Col>
      </Row>
    </MapContextProvider>
  );
};

MapPage.propTypes = {
  geocoderConfig: PropTypes.object.isRequired,
  layers: PropTypes.array.isRequired,
  layerControls: PropTypes.array.isRequired,
  mapConfig: PropTypes.object.isRequired,
  icons: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  popups: PropTypes.array.isRequired,
  zoomToLocation: PropTypes.func.isRequired,
  setLayersVisibility: PropTypes.func.isRequired,
  switchBasemap: PropTypes.func.isRequired,
  commonLegend: PropTypes.array.isRequired,
  layerLegends: PropTypes.object.isRequired,
};

export default withMapActions(MapPage);
