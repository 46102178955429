/**
 * Render Topics for the Interactive map.
 *
 * This component will render topics from `src/templates/topics`.
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import TopicContentHidden from "./TopicContentHidden";

const TopicContent = (props) => {
  const { elementId, ...contentProps } = props;

  const data = useStaticQuery(graphql`
    query TopicsTemplateData {
      topics: allFile(
        filter: {
          extension: { eq: "mdx" }
          sourceInstanceName: { eq: "templates" }
          relativeDirectory: { eq: "topics" }
        }
      ) {
        edges {
          node {
            name
            childMdx {
              frontmatter {
                id
              }
              body
            }
          }
        }
      }
    }
  `);

  if (!props.elementId) {
    return null;
  }

  const topicContent = data.topics.edges.filter(
    ({ node }) => node.childMdx.frontmatter.id === elementId
  )[0];

  return <TopicContentHidden topicContent={topicContent} {...contentProps} />;
};

TopicContent.propTypes = {
  elementId: PropTypes.string.isRequired,
  hideContent: PropTypes.bool.isRequired,
};

export default TopicContent;
