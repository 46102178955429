import React, { useState } from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import SectionHeading from "components/Sections/SectionHeading";

import EISExitContentLink from "./EISExitContentLink";
import EISTopicContent from "./EISTopicContent";
import EISTopicNextBack from "./EISTopicNextBack";
import EISTopicTitle from "./EISTopicTitle";

const DigitalEISPage = (props) => {
  const { topics, sectionHeadingProps, title } = props.content;
  const [topicId, setTopicId] = useState(topics[0].id);
  const [showContent, setShowContent] = useState(false);

  const allContent = topics.reduce((accumulater, value) => {
    accumulater[value.id] = value;
    return accumulater;
  }, {});
  const topicContent = allContent[topicId];

  const topicSelectors = topics.map((topic) => {
    return (
      <EISTopicTitle
        key={topic.id}
        setTopicId={setTopicId}
        setShowContent={setShowContent}
        id={topic.id}
        title={topic.title}
        currentId={topicId}
        icon={topic.icon}
        scrollToTop={topic.scrollToTop}
      />
    );
  });

  return (
    <Container>
      <Row noGutters className="flex-wrap-reverse">
        <Col sm={12}>
          <SectionHeading
            className="pt-3 d-none d-md-block"
            {...sectionHeadingProps}
          />
          <div className="overflow-hidden">
            <Collapse
              in={showContent}
              dimension="width"
              id="collapse-container"
              className="eis-page-collapse"
            >
              <div className="d-inline-block">
                <Container fluid className="p-0 m-0">
                  <Row
                    noGutters
                    className="text-primary topic-content-header d-flex d-md-none"
                  >
                    <Col xs={6} className="d-flex align-items-center p-3">
                      <h5 className="m-0">{title}</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-1">
                      <EISExitContentLink setShowContent={setShowContent} />
                      <h5 className="m-0">Section list</h5>
                    </Col>
                  </Row>
                  <Row className="topic-content-area" noGutters>
                    <Col
                      xs={6}
                      md={4}
                      xl={3}
                      className="d-flex flex-column justify-content-start p-2"
                    >
                      {topicSelectors}
                    </Col>
                    <Col
                      xs={6}
                      md={8}
                      xl={9}
                      className="d-flex flex-column p-3"
                    >
                      <EISTopicContent
                        elementId={topicContent.content.elementId}
                        hideContent={topicContent.content.hideContent}
                        mapConfig={topicContent.content.mapConfig}
                      />
                      <EISTopicNextBack
                        topics={topics}
                        currentId={topicId}
                        setTopicId={setTopicId}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

DigitalEISPage.propTypes = {
  content: PropTypes.shape({
    sectionHeadingProps: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    topics: PropTypes.array.isRequired,
  }).isRequired,
};

export default DigitalEISPage;
