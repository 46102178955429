import React, { useState, useEffect } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import EventCardSummaryRow from "components/EventCard/EventCardSummaryRow";

const MAX_NUMBER_OF_SUMMARY_EVENTS_DISPLAYED = 3;

const buildUpcomingEventsContent = (data) => {
  return data
    .slice(0, MAX_NUMBER_OF_SUMMARY_EVENTS_DISPLAYED)
    .map((upcomingEvent, index) => (
      <EventCardSummaryRow key={index} upcomingEvent={upcomingEvent} />
    ));
};

const upcomingEventsDisplayText = (text) => {
  return (
    <div className="mb-1">
      <Card.Text style={{ fontSize: "1rem" }}>{text}</Card.Text>
    </div>
  );
};

const loadingEventsContent = (
  <div className="d-flex justify-content-center pt-5">
    <Spinner className="text-center" animation="border" />
  </div>
);

const errorFetchingEventsContent = upcomingEventsDisplayText(
  "There were issues fetching upcoming events"
);

const noEventsContent = upcomingEventsDisplayText(
  "There are currently no upcoming events"
);

const EventCardSummary = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let fetchHeaders = {};
    if (process.env.GATSBY_WSIA_BACKEND_API_TOKEN) {
      fetchHeaders = {
        Authorization: `Token ${process.env.GATSBY_WSIA_BACKEND_API_TOKEN}`,
      };
    }
    const backendUrl = `${process.env.GATSBY_WSIA_BACKEND_BASE_URL}/api/upcoming_events/events`;

    axios
      .get(backendUrl, { headers: fetchHeaders })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setError(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((_) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  let cardContent = noEventsContent;
  if (loading) {
    cardContent = loadingEventsContent;
  } else if (error) {
    cardContent = errorFetchingEventsContent;
  } else if (data.length) {
    cardContent = buildUpcomingEventsContent(data);
  }

  return (
    <Card className="card-event-summary">
      <Card.Body>
        <div>
          <Card.Title style={props.title.style}>{props.title.text}</Card.Title>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {cardContent}
          </div>
        </div>
        {props.button && (
          <div>
            <Button
              className="button-event-summary"
              href={props.button.buttonUrl.path}
            >
              {props.button.text}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

EventCardSummary.propTypes = {
  buttonUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default EventCardSummary;
