import React from "react";

import PropTypes from "prop-types";
import { Folder } from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";

import TopicLayerSelector from "./TopicLayerSelector";

const TopicLayerDropDown = ({ id, title, layers }) => {
  const layerItems = layers.map((layer, index) => {
    return (
      <TopicLayerSelector key={`${id}-${index}`} {...layer}>
        {layer.label}
      </TopicLayerSelector>
    );
  });

  return (
    <Dropdown className="mt-2">
      <Dropdown.Toggle
        variant="outline-primary"
        className="topic-layer-drop-down text-left text-wrap"
        block
      >
        <span className="toggle-button-icon">
          <Folder size={18} />
        </span>{" "}
        <span className="small">{title}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="bg-light w-100 p-1 overflow-auto shadow topic-layer-drop-down-menu">
        {layerItems}
      </Dropdown.Menu>
    </Dropdown>
  );
};

TopicLayerDropDown.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  layers: PropTypes.array.isRequired,
};

export default TopicLayerDropDown;
