import React, { useEffect, useState } from "react";

import axios from "axios";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

const locationDisplayText = (text) => {
  return (
    <Card className="text-center">
      <Card.Body>
        <Card.Title className="text-center mb-0">{text}</Card.Title>
      </Card.Body>
    </Card>
  );
};

const loadingLocationContent = (
  <Card className="text-center">
    <Card.Body>
      <Spinner animation="border" />
    </Card.Body>
  </Card>
);

const errorFetchingLocationContent = locationDisplayText(
  "There were issues fetching hard copy viewing locations"
);

const noLocationContent = locationDisplayText(
  "There are currently no hard copy viewing locations"
);

const buildLocationContent = (locations) => {
  return (
    <ul>
      {locations.map((location) => {
        return (
          <li key={location.name}>
            <h5>{location.name}</h5>
            {location.addresses.map((address) => (
              <p key={address}>{address}</p>
            ))}
          </li>
        );
      })}
    </ul>
  );
};

const EISLocations = (props) => {
  const groupHeadingId = props.groupHeadingId;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let fetchHeaders = {};
    if (process.env.GATSBY_WSIA_BACKEND_API_TOKEN) {
      fetchHeaders = {
        Authorization: `Token ${process.env.GATSBY_WSIA_BACKEND_API_TOKEN}`,
      };
    }
    const backendUrl = `${process.env.GATSBY_WSIA_BACKEND_BASE_URL}/api/locations/`;

    axios
      .get(backendUrl, { headers: fetchHeaders })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error("Error fetching hard copy viewing locations:", error);
      });
  }, []);

  let cardGroupContent = noLocationContent;
  if (loading) {
    cardGroupContent = loadingLocationContent;
  } else if (error) {
    cardGroupContent = errorFetchingLocationContent;
  } else if (data.length) {
    cardGroupContent = buildLocationContent(data);
  }

  return (
    <Container className="mt-3">
      <Row className="mb-4">
        <Col xs={12}>
          <div
            id={groupHeadingId}
            className="internal-anchor-target"
            aria-hidden={true}
          />
          <h3 style={{ scrollMarginTop: "140px" }}>
            Hard copy viewing locations
          </h3>
          <hr style={{ width: "100%" }} />
          <p>
            Hard copies of the EIS are available to view at the locations listed
            below.
          </p>
          {cardGroupContent}
        </Col>
      </Row>
    </Container>
  );
};

export default EISLocations;
