import React, { useState } from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { EmojiFrown, EmojiNeutral, EmojiSmile } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga4";
import { useSelector, useDispatch } from "react-redux";

import { hideFeedbackPrompt } from "state/actions";

const FeedbackFloat = ({
  promptText,
  hideOnPages,
  header,
  description,
  feedbackId,
}) => {
  const dispatch = useDispatch();
  const hide = useSelector((state) => state.feedback.hide);
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);

  const activeSectionId =
    useSelector((state) => state.pageSections.activeSectionId) || "";

  const hideOnPage = () => {
    return hideOnPages.includes(activeSectionId);
  };

  if (hide || hideOnPage()) {
    return null;
  }

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    dispatch(hideFeedbackPrompt());
  };

  const handleAnswer = (choice) => {
    setAnswer(choice);
    ReactGA.event({
      category: activeSectionId,
      action: "User rating",
      label: choice,
    });
  };

  const handleLinkClick = () => {
    navigate(`/#${feedbackId}`);
    dispatch(hideFeedbackPrompt());
  };

  if (!show) {
    return (
      <Button
        onClick={handleOpen}
        className="rate-us-button position-fixed"
        variant="primary"
      >
        {promptText}
      </Button>
    );
  }

  return (
    <div className="position-fixed rate-us-prompt p-2 text-dark">
      <Row className="align-content-center align-items-center justify-content-center">
        <Col xs={12} className="mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <p className="font-weight-bold small m-0">
              {answer === "" ? header : "Thank you!"}
            </p>
            <Button
              onClick={handleClose}
              size="sm"
              variant="primary"
              className="font-weight-bold"
            >
              X
            </Button>
          </div>
        </Col>
        <Col xs={12} className="small mb-3">
          {answer === "" ? (
            description
          ) : (
            <div
              className="d-flex align-items-center"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <div>Click </div>
              <Button
                className="p-0"
                size="sm"
                variant="link"
                onClick={handleLinkClick}
              >
                here
              </Button>
              <div> to leave more feedback</div>
            </div>
          )}
        </Col>
        {answer === "" ? (
          <>
            <Col xs={4} className="text-center">
              <EmojiFrown
                size={40}
                onClick={() => handleAnswer("unhappy")}
                style={{ cursor: "pointer" }}
                className={answer === "unhappy" ? "selected-answer-float" : ""}
              />
            </Col>
            <Col xs={4} className="text-center">
              <EmojiNeutral
                size={40}
                onClick={() => handleAnswer("neutral")}
                style={{ cursor: "pointer" }}
                className={answer === "neutral" ? "selected-answer-float" : ""}
              />
            </Col>
            <Col xs={4} className="text-center">
              <EmojiSmile
                size={40}
                onClick={() => handleAnswer("happy")}
                style={{ cursor: "pointer" }}
                className={answer === "happy" ? "selected-answer-float" : ""}
              />
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};

FeedbackFloat.propTypes = {
  promptText: PropTypes.string.isRequired,
  hideOnPages: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  feedbackId: PropTypes.string.isRequired,
};

export default FeedbackFloat;
