import React from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

const EISTopicTitle = (props) => {
  const {
    id,
    title,
    setTopicId,
    currentId,
    icon,
    setShowContent,
    scrollToTop,
  } = props;
  const buttonVariant = id === currentId ? "primary" : "outline-primary";
  const iconPath = icon ? `/icons/digital_eis/${icon}` : "";
  const titleClass = icon
    ? "topic-title topic-title-with-icon"
    : "topic-title p-3 px-md-2 p-lg-3";

  const handleSetTopic = () => {
    setTopicId(id);
    setShowContent(true);
    if (scrollToTop) {
      window && window.scrollTo(0, 0);
    }
  };

  return (
    <Button
      onClick={handleSetTopic}
      block
      className={titleClass}
      variant={buttonVariant}
    >
      {iconPath && (
        <img
          src={iconPath}
          className="topic-title-icon"
          aria-hidden="true"
          alt=""
        />
      )}
      <h6 className="m-0 text-left">{title}</h6>
    </Button>
  );
};

EISTopicTitle.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setTopicId: PropTypes.func.isRequired,
  currentId: PropTypes.string.isRequired,
  setShowContent: PropTypes.func.isRequired,
  scrollToTop: PropTypes.bool,
};

EISTopicTitle.defaultProps = {
  scrollToTop: true,
};

export default EISTopicTitle;
