import React from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";

import {
  incrementLightboxKey,
  setActiveModal,
  setLightboxSourceIndex,
  setLightboxSources,
  toggleLightboxState,
} from "state/actions";

import { LIGHTBOX, LINK } from "./types";

const ConsultationRoomMenu = (props) => {
  const dispatch = useDispatch();

  const lightboxTrigger = (lightboxProps) => {
    dispatch(setLightboxSources(lightboxProps.lightboxSources));
    dispatch(setLightboxSourceIndex(lightboxProps.startingLightboxIndex));
    dispatch(incrementLightboxKey());
    // FsLightbox watches for CHANGES to the `toggler` prop, not the value of
    // the prop. If we rerender the lightbox (by changing the key) after we
    // have toggled the lightbox state then it FsLightbox won't be aware of
    // the change and won't trigger. Delay the change of the toggle state until
    // the "next tick" to let the FsLightbox component notice the change.
    setTimeout(() => {
      dispatch(toggleLightboxState());
    });
  };

  const linkAction = (linkProps) => {
    if (linkProps.targetModalId) {
      dispatch(setActiveModal(linkProps.targetModalId));
    } else {
      navigate(linkProps.path);
    }
  };

  return (
    <Dropdown style={{ position: "absolute", left: 5, top: 5 }}>
      <Dropdown.Toggle id="consultation-room-items" variant="primary">
        {props.scene.menuLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.scene.hotspots.map((hotspot, index) => {
          if (!hotspot.itemLabel) {
            return null;
          }
          if (hotspot.contentType === LIGHTBOX) {
            return (
              <Dropdown.Item
                style={{ whiteSpace: "normal", width: "350px" }}
                eventKey={index}
                as="button"
                key={hotspot.elementId}
                onClick={() => lightboxTrigger(hotspot.lightboxProps)}
              >
                {hotspot.itemLabel}
              </Dropdown.Item>
            );
          }
          if (hotspot.contentType === LINK && hotspot.linkProps.isExternal) {
            return (
              <Dropdown.Item
                style={{ whiteSpace: "normal", width: "350px" }}
                eventKey={index}
                href={hotspot.linkProps.path}
                key={hotspot.elementId}
                rel="noopener noreferrer"
                target={hotspot.linkProps.target}
              >
                {hotspot.itemLabel}
              </Dropdown.Item>
            );
          }
          if (hotspot.contentType === LINK) {
            return (
              <Dropdown.Item
                style={{ whiteSpace: "normal", width: "350px" }}
                eventKey={index}
                key={hotspot.elementId}
                onClick={() => linkAction(hotspot.linkProps)}
              >
                {hotspot.itemLabel}
              </Dropdown.Item>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ConsultationRoomMenu.propTypes = {
  scene: PropTypes.shape({
    menuLabel: PropTypes.string.isRequired,
    hotspots: PropTypes.arrayOf(
      PropTypes.shape({
        elementId: PropTypes.string.isRequired,
        itemLabel: PropTypes.string,
        position: PropTypes.shape({
          pitch: PropTypes.number.isRequired,
          yaw: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired
    ),
  }),
};

export default ConsultationRoomMenu;
