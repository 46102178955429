import React from "react";

import PropTypes from "prop-types";

const SectionText = ({ text, className }) => {
  return text.map((content, index) => {
    return (
      <p key={index} className={className}>
        {content}
      </p>
    );
  });
};

SectionText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default SectionText;
